import { defineStore } from 'pinia'
import dayjs from "dayjs";

export const useDeviceStore = defineStore({
    id: 'device',
    state: () => ({
        // deviceID: "B414-8138-649E-4B32",
        isAgreementReady: false,
        deviceID: "",
        appType: 11,
        agreementID: "W1K3BLNZ8G",
        shipName: "MSS-Ship",
        orderedData: {
            ukho: {
                avcs: [],
                adp: [],
                enp: [],
            },
            primar: {
                enc: []
            }
        },
        agreements: [],
        deliveries: []
    }),
    getters: {
        isAgreementLoaded: (state) => state.isAgreementReady,
        // getAgreementByDeviceID3: (state) => {
        //     return (val) => {
        //         return state.agreements[0]?.users.find((a) => a.deviceId === val)
        //     }
        // },
        getAgreementByDeviceID: (state) => (val) => {
                let flag = false
                let theAgreement = null
                state.agreements.forEach(agreement => {
                    let deviceID = agreement?.users.find((a) => a.deviceId == val)
                    if (deviceID) {
                        flag = true
                        theAgreement = agreement
                        return
                    }
                })
                return theAgreement
        },

        // getAgreementByDeviceID1: (state) => {
        //     let agreement = "1234"
        //     state.agreements.forEach(item => {
        //         agreement = item.users.find((a) => a.deviceId === state.deviceId)
        //         if (agreement) {
        //             return agreement
        //         }
        //     })
        //     return agreement
        // },

        getAgreementProviderByDeviceID: (state) => (val) => {
            // return (val) => state.agreements[0]?.users.find((a) => a.deviceId === val)?.availableOrderTypes[0] ?? ''

            let flag = false
            let theAgreement = null
            state.agreements.forEach(agreement => {
                let deviceID = agreement?.users.find((a) => a.deviceId == val)
                if (deviceID) {
                    flag = true
                    theAgreement = agreement.availableOrderTypes[0]
                    return
                }
            })
            return theAgreement
        },

        isDeviceInAgreement: (state) => (val) => {
            // return (val) => (state.agreements[0]?.users.find((a) => a.deviceId === val) !== undefined)

            let flag = false
            state.agreements.forEach(agreement => {
                let deviceID = agreement?.users.find((a) => a.deviceId == val)
                if (deviceID) {
                    flag = true
                    return
                }
            })
            return flag
        },
        isAgreementValid: (state) => dayjs(state.agreements[0]?.validTill).diff(dayjs()) > 0 ,
        getAgreementID: (state) => state.agreements[0]?.id,
        // getAgreementID: (state) => state.agreementID,

        isDeviceIDExist: (state) => state.deviceID !== '',
        getDeviceID: (state) => state.deviceID,
        getAppType: (state) => state.appType,
        getShipName: (state) => state.shipName,
        // getLatestLog: (state) => state.logbook[state.logbook.length - 1]
        // getLatestLog: (state) => {
        //     return () => state.logbook[state.logbook.length - 1]
        // },
        getDeliveries: (state) => state.deliveries,
        getDeliveriesCount: (state) => state.deliveries.length
    },
    actions: {
        loadData() {
            alert(1)
        },
        addAgreements(val) {
            this.agreements = val
            this.isAgreementReady = true
        },
        selectDevice(val) {
            this.deviceID = val
        },
        addOrdersHistoryAVCS(val) {
            this.orderedData.ukho.avcs = val
        },
        addDeliveries(val) {
          this.deliveries = val
        },
        reset() {
            //TODO
        }
    },
    persist: false
})
