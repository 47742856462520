<template>
  <div>
    <!--    <b-container v-show="momentaryStore.isBusy" fluid>-->
    <!--      <div align="center" class="text-centercenter text-danger my-2">-->
    <!--        <b-spinner class="align-middle"></b-spinner>-->
    <!--        <strong>&nbsp;Loading...</strong>-->
    <!--      </div>-->
    <!--    </b-container>-->

    <!--    <b-container v-show="!momentaryStore.isBusy" fluid>-->

    <b-overlay :show="!userStore.isInstallationsLoaded" opacity=0.9 bg-color="white" class="mt-4">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p v-if="!userStore.isInstallationsLoaded">Loading installations...</p>
        </div>
      </template>

      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-card header="LOGBOOK">
              <b-row>
                <b-col order=4 order-lg=1 lg="4">
                  <div v-show="displayContent === 'logbook'">
                    <logbook-table ref="logbook-table" :map=map></logbook-table>
                    <b-button variant="info" size="sm" @click="showAddReport()">Add Report
                    </b-button>
                  </div>
                  <div v-show="displayContent === 'report'">
                    <logbook-report ref="logbook-report"></logbook-report>
                  </div>
                </b-col>
                <b-col order=4 order-lg=1 lg="8">
                  <logbook-map :map=map :height=map_size></logbook-map>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

    </b-overlay>

    <!--    <b-container v-show="!momentaryStore.isBusy" fluid>-->
    <!--      <b-row>-->
    <!--        <b-col class="mt-2" v-show="displayContent === 'logbook'" order=4 order-lg=1 lg="4">-->
    <!--          <b-button block variant="primary" size="sm" @click="showLogbook()">logbook</b-button>-->
    <!--          <b-button class="mt-2" variant="outline-primary" size="sm" @click="showAddReport()">Add Report-->
    <!--          </b-button>-->
    <!--          <logbook-table class="mt-2" ref="logbook-table" :map=map></logbook-table>-->
    <!--        </b-col>-->
    <!--        <b-col class="mt-2" v-show="displayContent === 'report'" order=4 order-lg=1 lg="4">-->
    <!--          <logbook-report ref="logbook-report"></logbook-report>-->
    <!--        </b-col>-->

    <!--        <b-col order=1 order-lg=2 lg="8">-->
    <!--          <div>-->
    <!--            <logbook-map :map=map :height=map_size></logbook-map>-->
    <!--          </div>-->
    <!--        </b-col>-->

    <!--      </b-row>-->
    <!--    </b-container>-->

    <!--      <b-container fluid>-->
    <!--        <b-row>-->
    <!--          <b-col order=1 order-lg=2 lg="8">-->
    <!--            <logbook-map :map=map :height=map_size></logbook-map>-->
    <!--          </b-col>-->
    <!--          <b-col order=2 order-lg=1 lg="4">-->
    <!--            <logbook-table :map=map ></logbook-table>-->
    <!--          </b-col>-->
    <!--        </b-row>-->
    <!--      </b-container>-->
  </div>
</template>

<script>
import LogbookMap from "@/views/logbook/LogbookMap.vue";
import LogbookTable from "@/views/logbook/LogbookTable.vue";
import {logbooks_map} from "@/main";
import LogbookReport from "@/views/logbook/LogbookReport.vue";
import MyMap from "@/views/routes/RoutesMap.vue";
import OrdersMap from "@/views/routes/OrdersMap.vue";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useLogbookStore} from "@/stores/logbook";
import {loadInstallations} from "@/helpers/api";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";
import {useMomentaryStore} from "@/stores/momentary";
import Deliveries from "@/views/deliveries/Deliveries.vue";

export default {
  components: {Deliveries, LogbookReport, LogbookMap: LogbookMap, LogbookTable: LogbookTable},
  data() {
    return {
      displayContent: 'logbook',
      tabIndex: 0,

      selected_ship: "",
      ships: [],

      windowWidth: window.innerWidth,
      // map: new ShipLeaf(),
      map: logbooks_map,
      mapHeight: window.innerHeight / 1.2,
      mapHeightMobile: window.innerHeight / 1.8,
    }
  },
  watch: {
    'selected_ship'(newVal, oldVal) {
      this.deviceStore.selectDevice(newVal);
      this.$refs['logbook-report'].changeShip()
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    if (this.routesStore.getWaypointsCount > 0)
      this.map.drawRoute()

    if (this.logbookStore.logbook.length > 0)
      this.map.restoreReports()

    loadInstallations().then((response) => {
      if (this.deviceStore.isDeviceIDExist)
        this.selected_ship = this.deviceStore.getDeviceID
      else
        this.selected_ship = this.userStore.getShips[0].value
      this.ships = this.userStore.getShips
    })

    // let qrcode2 = require("@/data/mss-ship5.json")
    // this.$store.commit("fickle/setQR1", qrcode2)
  },
  computed: {
    ...mapStores(useMomentaryStore, useRoutesStore, useLogbookStore, useUserStore, useDeviceStore),
    is_mobile() {
      return this.windowWidth <= 760
    },
    map_size() {
      if (this.is_mobile)
        return window.innerHeight / 1.8
      else
        return window.innerHeight / 1.2
    },
  },
  methods: {
    showAddReport() {
      this.$refs['logbook-report'].form.lat = ''
      this.$refs['logbook-report'].form.lon = ''
      this.displayContent = 'report'
    },
    showLogbook() {
      this.displayContent = 'logbook'
    },
    getLogbookButtonStatus(type) {
      if (this.displayContent === 'logbook' && type === 'logbook')
        return 'primary'
      else
        return 'outline-primary'
    },
  }
}
</script>
