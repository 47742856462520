<template>
  <div>
<!--    12 {{deviceStore.deviceID}}<br>-->
<!--    12 {{deviceStore.getAgreementByDeviceID(deviceStore.deviceID)}}-->

<!--    {{deviceStore.getAgreementProviderByDeviceID(deviceStore.getDeviceID)}}-->

<!--    0  {{deviceStore.isAgreementLoaded && deviceStore.isDeviceInAgreement(deviceStore.getDeviceID) && !deviceStore.isAgreementValid}}-->
<!--    0  {{deviceStore.isAgreementValid}}<br>-->
<!--    0  {{dayjs().diff(deviceStore.isAgreementValid)}}<br>-->
<!--    0  {{dayjs().diff("2024-12-11T16:00:00")}}<br>-->
<!--    0  {{dayjs(deviceStore.isAgreementValid).diff(dayjs())}}<br>-->
<!--    0  {{dayjs(deviceStore.isAgreementValid).diff(dayjs()) > 0}}<br>-->

<!--    <b-modal id="modal-devices" title="Agreement warning" v-model="deviceStore.isAgreementLoaded && deviceStore.isDeviceInAgreement(deviceStore.getDeviceID) && !deviceStore.isAgreementValid" ok-only>-->
    <b-modal title="Agreement warning" v-model="isAgreementInvalid" ok-only>
      <div class="text-sm-left" id="up">
        The service period under your contract has expired for the vessel. Please enter into a new contract.
      </div>
    </b-modal>

    <b-modal title="Agreement warning" v-model="isAgreementMissing" ok-only>
      <div class="text-sm-left" id="up">
        This vessel is not serviced under contract. You can enter into a contract for servicing this vessel.
      </div>
    </b-modal>

    <b-modal title="Server ERROR" v-model="momentaryStore.isAPIError" @ok="reloadPage()" ok-only>
      <div class="text-sm-left" id="up">
        An error with connection to server. Try again.
      </div>
    </b-modal>


<!--    0{{deviceStore.getDeviceID}}<br>-->
<!--    1{{deviceStore.getAgreementByDeviceID(deviceStore.getDeviceID)}}<br>-->
<!--    2{{deviceStore.isDeviceInAgreement(deviceStore.getDeviceID)}}<br>-->
<!--    3{{userStore.getAppTypeByDeviceID(deviceStore.getDeviceID)}}<br>-->
<!--    4{{deviceStore.getAgreementID}}<br>-->
<!--    5{{userStore.getInstallationByDeviceID(deviceStore.getDeviceID)}}<br>-->
<!--    <b-overlay :show="loadingFlag" rounded="sm" spinner-variant="danger">-->
<!--      <b-modal ref="routeSaved" title="Route" ok-only>-->
<!--        <p>The route saved</p>-->
<!--      </b-modal>-->

<!--      <b-container v-show="momentaryStore.isBusy" fluid>-->
<!--      <b-container v-show="!userStore.isInstallationsLoaded || !deviceStore.isAgreementLoaded" fluid>-->
<!--        <div align="center" class="text-centercenter text-danger my-2">-->
<!--          <b-spinner class="align-middle"></b-spinner>-->
<!--          <strong>&nbsp;Loading...</strong>-->
<!--        </div>-->
<!--      </b-container>-->

      <b-overlay :show="!userStore.isInstallationsLoaded || !deviceStore.isAgreementLoaded" opacity=0.9 bg-color="white" class="mt-4">
        <template #overlay>
          <div class="text-center">
            <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
            <p v-if="!userStore.isInstallationsLoaded && !deviceStore.isAgreementLoaded">Loading agreements, installations...</p>
            <p v-if="!userStore.isInstallationsLoaded && deviceStore.isAgreementLoaded">Loading installations...</p>
            <p v-if="userStore.isInstallationsLoaded && !deviceStore.isAgreementLoaded">Loading agreements...</p>
          </div>
        </template>

        <b-container fluid>
        <!--        {{routesStore.getRoute}}-->


        <b-overlay opacity="0.3" :show="$store.state.routes.progress.isCalculating" rounded="sm">

          <template #overlay>
            <div class="text-center">
              <b-card>
                <p id="cancel-label">Route/Safety calculating...</p>

                <b-progress class="mt-2" :max=100 show-value show-progress animated>
                  <b-progress-bar :value="routingProgress()"
                                  :label="`${(routingProgress())}%`"
                                  variant="primary"></b-progress-bar>
                </b-progress>

                <b-button class="mt-2" size="sm" @click="cancelRoute()" variant="danger">Cancel</b-button>
              </b-card>
            </div>
          </template>


          <b-row class="mt-2">


            <b-col v-show="displayContent === 'showRoute'" order=4 order-lg=1 lg="4">


              <!--            <b-container>-->
              <!--              <b-row>-->
              <div>
                <b-button-group block class="mb-2 btn-block">
                  <b-button :variant="getRouteButtonStatus('ports')" size="sm" @click="showPorts()">Ports</b-button>
                  <b-button :variant="getRouteButtonStatus('routes')" size="sm" @click="showRoutes()">Routes</b-button>
                  <b-button :variant="getRouteButtonStatus('settings')" size="sm" @click="showSettings()">Settings
                  </b-button>
                </b-button-group>
              </div>
              <!--              </b-row>-->
              <!--            </b-container>-->

              <!--            <h6 v-if="!is_mobile" align="center" class="mt-2">ROUTE. WAYPOINTS</h6>-->
              <!--            <div class="mt-1 mb-1">-->

              <!--              <b-dropdown size="sm" variant="info" right text="Waypoints">-->
              <!--                <b-dropdown-item disable @click="">Weather</b-dropdown-item>-->
              <!--              </b-dropdown>-->

              <!--              <b-dropdown class="ml-1" size="sm" variant="info" right text="Files">-->
              <!--                <b-dropdown-item @click="newRoute()">New</b-dropdown-item>-->
              <!--                <b-dropdown-divider></b-dropdown-divider>-->
              <!--                <b-dropdown-item @click="loadRoute()">Load</b-dropdown-item>-->
              <!--                <b-dropdown-item @click="saveRoute()">Save</b-dropdown-item>-->
              <!--                <b-dropdown-item disabled>Edit</b-dropdown-item>-->
              <!--              </b-dropdown>-->

              <!--              <b-button-group>-->
              <!--&lt;!&ndash;                <b-button class="ml-1" size="sm" @click="displayContent = 'showSettings'" variant="info">Settings</b-button>&ndash;&gt;-->
              <!--&lt;!&ndash;                <b-button @click="sendRouteToOrder" v-if="$store.state.routes.route.length > 1" class="ml-1" size="sm" variant="warning">Order ENCs</b-button>&ndash;&gt;-->
              <!--&lt;!&ndash;                <b-button class="ml-1" size="sm" @click="createPDF()" variant="info">Generate PDF</b-button>&ndash;&gt;-->
              <!--              </b-button-group>-->
              <!--            </div>-->

              <b-row>
                <b-col>
                  <b-form-select v-model="selected_ship" :options="ships" size="sm"></b-form-select>
                </b-col>
              </b-row>

              <b-card no-body>
              <b-tabs v-model="tabIndex" card small>
                <b-tab title="Files">
                  <!--                  <cells-by-route ref="cells-by-route"></cells-by-route>-->
                  <div class="mt-2">
                    <b-button size="sm" variant="primary">Save</b-button>&nbsp;
                    <b-button size="sm" variant="primary">Load</b-button>
                  </div>
                </b-tab>
                <b-tab title="WP" active>
                  <!--                <voyage-plan v-if="!is_mobile"></voyage-plan>-->
                  <RouteTable v-if="routesStore.getWaypointsCount > 0" :fields="fields"></RouteTable>
                  <!--                  <b-card v-if="this.$store.state.routes.route.length <= 1" class="mt-2" bg-variant="light">-->
                  <!--                    To create a new route use 'Ports' button. Set the required draught and other route parameters using the 'Settings' button.-->
                  <!--                  </b-card>-->
                </b-tab>
                <!--                <b-tab title-item-class="d-none" title="111" @click="returnToSettings()">-->
                <!--                  &lt;!&ndash;                  <route-settings></route-settings>&ndash;&gt;-->
                <!--                </b-tab>-->
                <b-tab title="Weather" @click="returnToWeather()">
                  <weather ref="weather"></weather>
                </b-tab>
                <!--                <b-tab title-item-class="d-none" title="Zones">-->
                <!--                  &lt;!&ndash;                  <zones-settings></zones-settings>&ndash;&gt;-->
                <!--                </b-tab>-->
                <b-tab :title-item-class="getTabStatus()" @click="tabPassagePlanClicked()" title="P.Plan">
                  <passage-plan ref="passage-plan"></passage-plan>
                </b-tab>
                <b-tab title="Folio" :disabled="isAgreementInvalid || isAgreementMissing">
                  <!--                  <cells-by-route ref="cells-by-route"></cells-by-route>-->
                  <orders-table :map=ordersMap ref="order-tab"></orders-table>
                </b-tab>
                <!--                <b-tab title="Files">-->
                <!--                  &lt;!&ndash;                  <cells-by-route ref="cells-by-route"></cells-by-route>&ndash;&gt;-->
                <!--                  <div class="mt-2">-->
                <!--                    <b-button size="sm" variant="primary">Save Route</b-button>&nbsp;-->
                <!--                    <b-button size="sm" variant="primary">Load Route</b-button>-->
                <!--                  </div>-->
                <!--                </b-tab>-->

              </b-tabs>
              </b-card>

            </b-col>


            <b-col v-show="displayContent === 'showSearch'" order=4 order-lg=1 lg="4">
              <h6 align="center" class="mt-2">SEARCH</h6>
              <b-button class="border-0 float-right" size="sm" variant="outline-danger"
                        @click="displayContent = 'showRoute'">
                <!--                        @click="clearPorts();displayContent = 'showRoute'">-->
                <b-icon icon="x" font-scale="1.5"></b-icon>
              </b-button>
              <br><br>
              <search ref="search-panel"></search>
            </b-col>

            <b-col v-show="displayContent === 'showSettings'" order=4 order-lg=1 lg="4">
              <h6 align="center" class="mt-2">SETTINGS</h6>
              <b-button class="border-0 float-right" size="sm" variant="outline-danger"
                        @click="displayContent = 'showRoute';restoreSearch()">
                <b-icon icon="x" font-scale="1.5"></b-icon>
              </b-button>
              <br><br>
              <route-settings></route-settings>
            </b-col>

            <!--          <b-col v-show="displayContent === 'showSettings'" order=4 order-lg=1 lg="4">-->
            <!--            <h6 align="center" class="mt-2">SETTINGS</h6>-->
            <!--            <b-button class="border-0 float-right" size="sm" variant="outline-danger"-->
            <!--                      @click="displayContent = 'showRoute'">-->
            <!--              <b-icon icon="x" font-scale="1.5"></b-icon>-->
            <!--            </b-button>-->
            <!--            <br><br>-->
            <!--            <RouteSettings></RouteSettings>-->
            <!--          </b-col>-->

            <b-col v-if="displayContent === 'showInfo'" order=4 order-lg=1 lg="4">
              <h6 align="center" class="mt-2">MAP INFO</h6>
              <b-button class="border-0 float-right" size="sm" variant="outline-danger" @click="closeMapInfo()">
                <b-icon icon="x" font-scale="1.5"></b-icon>
              </b-button>

              <div v-if="!$store.state.fickle.routes.objectsInfoLoading">
                <b-button v-if="map_info_text.length > 0" class="border-0 float-right" size="sm"
                          variant="outline-secondary" @click="closeMapInfoItem()">
                  <b-icon icon="arrow-left-short" font-scale="1.5"></b-icon>
                </b-button>
                <div v-for="(item, i) in $store.state.fickle.routes.objectsInfo.info">
                <span class='listsmall'><a href='#'
                                           @click='showObject(item.id, item.name, item.locationType, i+1, true)'>{{
                    i + 1
                  }}: {{
                    item.name
                  }} </a><br></span>
                  <!--                <span v-if="map_info_object !== item.id" class='listsmall'><a href='#' @click='showObject(item.id, item.name, item.locationType, i+1)'>{{ i+1 }}: {{ item.name }} </a><br></span>-->
                  <!--                <span v-if="map_info_object.length > 0 && map_info_object === item.id" class='listsmall'>{{ i+1 }}: {{ item.name }} <br></span>-->
                </div>
                <hr>
                <div v-html="map_info_text"/>
              </div>
              <div class="text-center text-danger my-2" v-else>
                <b-spinner small class="align-middle"></b-spinner>
                <strong>&nbsp;Loading...</strong>
              </div>


              <!--            <div v-for="(item) in $store.state.fickle.routes.objectsInfo.info">-->
              <!--              <details><summary>{{ item.name }}</summary>-->
              <!--                <ul>-->
              <!--                <div v-for="(attribute) in item.attributes">-->
              <!--                  <li><span class='listsmall'>{{ attribute.name }}: </span>-->
              <!--                  <span class='small-black'>{{ attribute.value }}</span></li>-->
              <!--                </div>-->
              <!--                  <li v-if="item.lines.length > 0"><span class='listsmall'><a href='#' @click='showObject(item.name)'>Show on map</a></span></li>-->
              <!--                </ul>-->
              <!--              </details>-->
              <!--            </div>-->
              <!--            <div v-html="map_info_text"/>-->
            </b-col>

            <b-col order=1 order-lg=2 lg="8">
              <div v-if="tabIndex === this.tabIndexCharts">
                <orders-map :map=ordersMap :height=map_size></orders-map>
              </div>
              <div v-if="tabIndex !== this.tabIndexCharts">
                <my-map :map=map :height=map_size></my-map>
              </div>

              <!--      <div v-else>-->
              <!--        {{tabIndex}}-->
              <!--        <my-map :map=map :height=map_size></my-map>-->
              <!--      </div>-->

            </b-col>

          </b-row>

        </b-overlay>
      </b-container>
      </b-overlay>
<!--    </b-overlay>-->

  </div>
</template>

<script>
import RoutesMap from "@/views/routes/RoutesMap";
import RouteSettings from "@/views/routes/RouteSettings.vue";
import RouteTable from "@/views/routes/RouteTable.vue";
import Weather from "@/views/routes/Weather.vue";
import ZonesSettings from "@/views/routes/ZonesSettings.vue";
import Search from "@/views/routes/Search.vue";
import {eventBus} from "@/main";
import {routes_map, orders_map} from "@/main";
import router from "@/router";
import store from "@/store";
import {useMomentaryStore} from "@/stores/momentary";
import {loadENCData, loadUserData} from "@/helpers/offline";
import OrdersMap from "@/views/routes/OrdersMap.vue";
import {useRoutesStore} from "@/stores/routes";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {mapStores} from "pinia";
import {createVoyagePlan} from "@/views/routes/components/VoyagePlan";
import PassagePlan from "@/views/routes/PassagePlan.vue";
import CellsByRoute from "@/views/routes/CellsByRoute.vue";
import OrdersTable from "@/views/routes/OrdersTable.vue";
import weather from "@/views/routes/Weather.vue";
import {myAxios, myAxiosRetry} from "@/services/myAxios";
import dayjs, {isDayjs} from "dayjs";
import search from "@/views/routes/Search.vue";
import {useLogbookStore} from "@/stores/logbook";
import {useUserStore} from "@/stores/user";
import {useDeviceStore} from "@/stores/device";
import {loadAgreements, loadInstallations} from "@/helpers/api";
import Agreement from "@/views/agreement/Agreement.vue";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {
    Agreement,
    RouteTable,
    MyMap: RoutesMap,
    OrdersMap: OrdersMap,
    OrdersTable: OrdersTable,
    RouteSettings,
    Search,
    Weather: Weather,
    ZonesSettings,
    PassagePlan,
    CellsByRoute
  },
  data() {
    return {
      // STORE_ROUTES: useRoutesStore(),

      interval: null,

      selected_ship: "",
      ships: [],

      needToRestoreSearch: false,

      preparingPassagePlan: true,

      displayContent: 'showRoute',
      map_info_text: '',
      map_info_object: '',

      loadingFlag: false,
      // spinner: false,
      windowWidth: window.innerWidth,
      map: routes_map,
      ordersMap: orders_map,

      mapHeight: window.innerHeight / 1.2,
      mapHeightMobile: window.innerHeight / 1.8,

      tabIndex: 0,
      tabIndexFiles: 0,
      tabIndexWaypoints: 1,
      tabIndexWeather: 2,
      tabIndexPassagePlan: 3,
      tabIndexCharts: 4,

      fields: [
        {key: 'name', label: 'name', type: 'string', tdClass: 'small-black', thClass: 'small-gray',},
        {key: 'lat', label: 'Lat', type: 'number', tdClass: 'small-black', thClass: 'small-gray',},
        {key: 'lon', label: 'Lon', type: 'number', tdClass: 'small-black', thClass: 'small-gray',},
      ],


      show_spinner: false,
      show_table: false,
      periodWarning: false,
      periodWarningList: [],
      updateWarning: false,
      priceCalculating: false,
      openCellsList: false,
      catalogADP: false,
      catalogENP: false,
      selectedPeriod: {period_code: "4", period: "3"},

      cellList: "",
      selectedProduct: 'Primar',
      options: [
        {item: 'Primar', name: 'Primar charts'},
        {item: 'Navicom', name: 'Navicom charts'},
      ],

      cellsBands: [0, 0, 0, 0, 0, 0],

      show_add_ship_tab: "d-none",
      selected_tab: 1,

      agreement: null,

      selected_ecdis: null,
      showHint: false,
      checkOrder: false,
      show_check_order_spinner: true,

      excludedCells: [],
      includedCells: [],
      notFoundCells: [],

      network: {
        isError: false,
        code: '',
      },

      // map: new ChartsLeafUKHO(true),
      haveRoute: false,
      log: "",
      chart_types: [
        {title: "Products"},
        {title: "S63/Primar"},
      ],
      licensing: {},
      selected: 0,
      bagSelected: false,


      orders: {
        isMapInitialized: false
      }

    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    // alert(1)
    if (this.routesStore.getWaypointsCount > 0)
      routes_map.redrawRoute()

    // routes_map.drawLogbookState()

    loadInstallations().then((response) => {
      if (this.deviceStore.isDeviceIDExist)
        this.selected_ship = this.deviceStore.getDeviceID
      else
        this.selected_ship = this.userStore.getShips[0].value
      this.ships = this.userStore.getShips
    })

    loadAgreements().then((response) => {
    })

    // eventBus.$emit('control-clear-route')
    // eventBus.$emit('control-clear-global-route')
    this.$store.dispatch('current/clearCart')
    this.$store.dispatch('addS63Items', [])
    // window.location.reload()

    // this.interval = setInterval(checkInternetLoop, 5000)

    // if (this.$route.query?.rid !== '' && this.$route.query?.rid !== undefined) {
    //   myAxios.get(`http://route-builder.m-s-solutions.net/routes/ca5ff137-54cf-4948-a1eb-1a9201c82cd9`)
    //       .then(resp => {
    //         store.commit("fickle/setGlobalRoute", resp.data)
    //       });
    //
    //
    // }


    // if (this.$store.state.routes.route.length > 0) {
    //   eventBus.$emit('restore-route')
    // } else {
    //   eventBus.$emit('load-route-data')
    // }

    eventBus.$off('switch-wp-tab')

    eventBus.$off('search-on-map')
    eventBus.$off('send-order-route')
    eventBus.$off('show-map-info')
    eventBus.$off('change-tab-zones')
    eventBus.$off('show-waypoints')

    // eventBus.$on('change-tab-zones', () => {
    //   this.tabIndex = 3
    // })


    eventBus.$on('switch-wp-tab', () => {
      this.tabIndex = this.tabIndexWaypoints
    })

    eventBus.$on('search-on-map', () => {
      this.displayContent = 'showSearch'
    })

    eventBus.$on('show-waypoints', () => {
      this.displayContent = 'showRoute'
    })

    eventBus.$on('send-order-route', () => {
      router.push({name: 'charts'}).catch(() => {
      });
    })

    eventBus.$on('show-map-info', (payload) => {
      this.map_info_text = ""
      this.map_info_object = ""
      // eventBus.$emit('clear-object')
      // routes_map.aaaa()
      store.commit("fickle/setObjectInfo", payload)


      // this.map_info_text = ''
      // payload.info.forEach(item => {
      //   this.map_info_text += "<details><summary>" + item.name + "</summary><ul>"
      //   item.attributes.forEach(attribute => {
      //     this.map_info_text += "<li><span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span></li>"
      //   })
      //   if (item.lines.length > 0) {
      //     this.map_info_text += "<li><span class='listsmall'><a href='#' :click=showObject()>Show on map</a></span></li>"
      //   }
      //   this.map_info_text += "</ul></details>"
      // })
      this.displayContent = 'showInfo'
    })

  },
  watch: {
    'selected_ship'(newVal, oldVal) {
      this.deviceStore.selectDevice(newVal);

      this.$refs['order-tab'].getHistory().then(resp => {
        this.deviceStore.addOrdersHistoryAVCS(resp.avcsCells)
      })

      // this.$refs['deliveries'].checkUpdates()
    },
    'routesStore.getWaypointsCount'(newVal, oldVal) {
      if (newVal === 0) {
        this.tabIndex = this.tabIndexWaypoints
      }
    },
    'tabIndex'(newVal, oldVal) {
      if (newVal === this.tabIndexCharts) {
        if (!store.state.fickle.offline.userDataReady) {
          loadUserData().then(resp => {
            console.log("off, USER DONE")
          })
        }

        if (!store.state.fickle.offline.ukhoEncDataReady) {
          loadENCData("ukho").then(resp => {
            console.log("off, ENC DONE")
            this.init_map()
          })
        } else {
          this.init_map()
        }
      } else if (newVal === this.tabIndexWaypoints) {
        routes_map.addNoGoLayer()
        routes_map.showControls()
      } else if (newVal === this.tabIndexWeather) {
        this.prepareWeather(newVal)
        routes_map.hideControls()
      }
    }

    //   if (newVal === 0 || newVal === 1)
    //     eventBus.$emit('hide-zones')
    //   if (newVal === 2)
    //     eventBus.$emit('show-zones')
    // },
  },
  computed: {
    dayjs() {
      return dayjs
    },
    isAgreementInvalid() {
      if (!this.deviceStore.isAgreementLoaded || this.deviceStore.getDeviceID === '')
        return false
      else if (this.deviceStore.isAgreementLoaded && this.deviceStore.isDeviceInAgreement(this.deviceStore.getDeviceID) && !this.deviceStore.isAgreementValid)
        return true
      else
        return false
    },
    isAgreementMissing() {
      if (!this.deviceStore.isAgreementLoaded || this.deviceStore.getDeviceID === '')
        return false
      else if (this.deviceStore.isAgreementLoaded && !this.deviceStore.isDeviceInAgreement(this.deviceStore.getDeviceID))
        return true
      else
        return false
    },

    ...mapStores(useMomentaryStore, useRoutesStore, useLogbookStore, useUserStore, useDeviceStore),

    // routePointsCount() {
    //   return this.routesStore.routeResponse.routePoints.length;
    // },
    // routePoints() {
    //   return this.routesStore.routeResponse.routePoints
    // },
    // routes_store() {
    //   return this.STORE_ROUTES
    // },
    is_mobile() {
      return this.windowWidth <= 760
    },
    map_size() {
      if (this.is_mobile)
        return window.innerHeight / 1.8
      else
        return window.innerHeight / 1.2
    },
  },
  methods: {
    isDayjs,
    changeSettings() {
      this.needToRestoreSearch = true
      this.showSettings()
    },
    reloadPage() {
      window.location.reload()
    },
    restoreSearch() {
      if (this.needToRestoreSearch)
        this.$refs["search-panel"].showCalculateModal = true
    },
    routePointInfos(index) {
      return this.routesStore.getWaypoints[index].info
    },

    datem(seconds) {
      let date = this.routesStore.getStartDate
      let time = this.routesStore.getStartTime
      let datetime = dayjs(date + " " + time, "YYYY-MM-DD HH:mm")
      let datetimenew = datetime.add(seconds, "seconds")
      let shortdate = datetimenew.format("DD/MM HH:mm")

      return shortdate
    },
    dateu(seconds) {
      let date = this.routesStore.getStartDate
      let time = this.routesStore.getStartTime
      let datetime = dayjs(date + " " + time, "YYYY-MM-DD HH:mm")
      let datetimenew = datetime.add(seconds, "seconds")
      let shortdate = datetimenew.unix()

      return shortdate
    },
    prepareWeather() {
      if (this.routesStore.getWaypointsCount === 0)
        return

      let weather = []
      let startdate = this.routesStore.getStartDate
      let starttime = this.routesStore.getStartTime

      store.commit("fickle/setWeatherWP", weather)
      let pts = this.routesStore.getWaypoints
      this.routesStore.initWeather(pts.length)
      pts.forEach((coordinates, i) => {
        let lat = coordinates.lat
        let lon = coordinates.lon

        let datetime = this.dateu(this.routePointInfos(i).fromStartSeconds)

        // const newDate = dayjs().add(this.dateOffset, 'days').format("YYYY-MM-DD");
        // const newTime = dayjs().add(this.timeOffset, 'hour').format("HH:mm");
        //
        // const datetime = dayjs(newDate + " " + newTime).unix()

        let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + lat + '&lon=' + lon + '&t=' + datetime

        myAxiosRetry.get(URL).then(resp => {
          // console.log("aaaa", i, resp.data)
          this.routesStore.addWeather(resp.data, i)
          // weather.push(resp.data)
        }).catch(error => {
          this.routesStore.addWeather({temperature: 'N/A', wind: 'N/A', windAngle: 'N/A'}, i)
        })

        // store.commit("fickle/setWeatherWP", weather)
      })


      // store.commit("fickle/setWeatherDateTime", this.dateu(this.$store.state.routes.info[this.currentIndex].fromStartSeconds))
      // if (this.showWind || this.showTemperature)
      //   eventBus.$emit('change-weather')
    },
    // createPDF() {
    //   // const routesStore = useRoutesStore()
    //   createVoyagePlan()
    // },
    newRoute() {
      this.map.removeRoute()
    },
    routingProgress() {
      let progress = this.$store.state.routes.progress.calculatingProgress
      if (progress > 100)
        progress = 100

      return progress
    },

    init_map() {
      console.log("order init_map")
      // if (this.isMapInitialized)
      //   return
      // else
      //   this.isMapInitialized = true
      //
      // alert(1)

      console.log("hhh 1")

      console.log("c-- init map", new Date().getMinutes(), new Date().getSeconds())
      console.log("init map")
      // eventBus.$emit('restore-primar-tiles')
      ////eventBus.$emit('add-primar-controls')


      if (this.$store.state.charts.s63.products.all.length > 0) {
        // setInterval(() => {this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info)}, 3000)
        this.ordersMap.drawAllCells(this.$store.state.charts.s63.primar_map_info);
        this.$store.dispatch('current/drawing', false).then(() => {
          this.tabOrderClicked()
        })

        // this.selectCellByRoute()
        console.log("order init_map 1")

      } else {
        this.$store.dispatch('getENCs').then(() => {
          console.log("c-- draw", new Date().getMinutes(), new Date().getSeconds())
          console.log('init draw all cells from', file)
          // setInterval(() => {this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info)}, 3000)
          this.ordersMap.drawAllCells(this.$store.state.charts.s63.primar_map_info);
          this.$store.dispatch('current/drawing', false).then(() => {
            this.tabOrderClicked()
          })
          console.log("order init_map 2")

          // this.selectCellByRoute()
        });
      }

      console.log("hhh 2")


    },


    getTabStatus() {
      if (this.routesStore.getWaypointsCount > 0)
        return ''
      else
        return 'd-none'
    },
    cancelRoute() {
      routes_map.cancelAutorouting()
    },
    tabOrderClicked() {
      console.log("order tabOrderClicked")
      if (this.routesStore.getWaypointsCount > 0) {
        this.$refs["order-tab"].prepareCells()
      }
      // let points = []

      // this.$refs["cells-by-route"].prepareCells()

      // this.$parent.$parent.$parent.$parent.loadingFlag = true
      // this.$parent.$parent.loadingFlag = true

      // this.routesStore.route.forEach(item => {
      //   points.push({lat: item.lat, lon: item.lon})
      // })
      //
      // return new Promise(resolve => {
      //   myAxiosRetry.post("api/v1/mss/maps", points
      //   ).then(resp => {
      //     this.momentaryStore.addChartsByRoute(resp.data)
      //     router.push({name: "proxy-orders"});
      //     resolve()
      //   });
      // })
    },
    tabPassagePlanClicked() {
      this.$refs["passage-plan"].createPDF()
    },
    showPorts() {
      this.displayContent = 'showSearch'
    },

    showRoutes() {
      this.displayContent = 'showRoute'
    },
    showSettings() {
      this.displayContent = 'showSettings'
    },

    // showOrders() {
    //   router.push({name: "proxy-orders"});
    // },

    getRouteButtonStatus(type) {
      if (this.displayContent === 'showRoute' && type === 'routes')
        return 'primary'
      else
        return 'outline-primary'
    },


    // restoreRoute() {
    //   // if (this.routesStore.getWaypointsCount > 0)
    //   //   routes_map.redrawRoute()
    // },
    returnToWeather() {
      // this.restoreRoute()

      eventBus.$emit('show-weather')
      eventBus.$emit('init-weather')
    },
    closeMapInfo() {
      this.displayContent = 'showRoute'
      routes_map.clearInfoObjects()
      // eventBus.$emit('clear-object')
    },
    closeMapInfoItem() {
      this.map_info_text = ''
      this.map_info_object = ''
      routes_map.clearInfoObjects()
      // eventBus.$emit('clear-object')
    },
    showObject(id, name, type, i, needZoom) {
      this.map_info_text = "<span class='small-cyan'>" + i + ": " + name + " (" + type + ")</span><hr>"
      this.map_info_object = id

      // this.map_info_text = ''
      this.$store.state.fickle.routes.objectsInfo.info.forEach((item, index) => {
        if (item.name === name && i - 1 === index) {

          this.map_info_text += ""
          item.attributes.forEach((attribute, i) => {
            this.map_info_text += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
          })
          this.map_info_text += "<hr>"
        }
      })


      // let x = this.$store.state.fickle.routes.objectsInfo.info;
      let points = this.$store.state.fickle.routes.objectsInfo.info.filter(function (e) {
        // console.log(x.findIndex(name))

        return e["id"] === id;
      });

      let lines = points[0].lines

      // if (name === "Depth area") {
      //   lines.pop()
      // }

      console.log("mmmm2", name, id)
      console.log("mmmm2", lines)

      routes_map.drawInfoObject({lines: lines, type: type, zoom: needZoom})

      // eventBus.$emit("draw-object", {lines: lines, type: type, zoom: needZoom})


    },
    saveRoute() {
      eventBus.$emit('keep-route-settings')
      // localStorage.setItem('route', JSON.stringify(this.$store.state.routes.route));
      // localStorage.setItem('route-settings', JSON.stringify(this.$store.state.routes.route_settings));
      this.$refs['routeSaved'].show()
    },
    loadRoute() {
      // let route = JSON.parse(localStorage.getItem('route'))
      // let settings = JSON.parse(localStorage.getItem('route-settings'))
      this.$store.commit('routes/setRoutePoints', route)
      this.$store.commit('routes/setRouteSettings', settings)
      eventBus.$emit('restore-route')
    },
    clearPorts() {
      this.map.clearPorts();
    },
    async onLoadingFlag() {
      this.loadingFlag = true;
    },
    // async sendRouteToOrder() {
    //   await this.onLoadingFlag();
    //   setTimeout(() => {
    //     this.$store.commit("routes/useRouteForOrder", true)
    //     eventBus.$emit('send-order-route')
    //   }, 600)
    //
    // },
  },
}
</script>

<!--<script>-->
<!--// eslint-disable-next-line no-unexpected-multiline-->
<!--(function(d,t) {-->
<!--var BASE_URL="https://app.chatwoot.com";-->
<!--var g=d.createElement(t),s=d.getElementsByTagName(t)[0];-->
<!--g.src=BASE_URL+"/packs/js/sdk.js";-->
<!--g.defer = true;-->
<!--g.async = true;-->
<!--s.parentNode.insertBefore(g,s);-->
<!--g.onload=function(){-->
<!--window.chatwootSDK.run({-->
<!--websiteToken: 'nrnUHZ5ZQ66jotURNVUDNuet',-->
<!--baseUrl: BASE_URL-->
<!--})-->
<!--}-->
<!--})(document,"script");-->
<!--</script>-->