import {RouteLeafDraw} from "@/my-leaf/routes/route-leaf-draw";
import * as waypoints from "@/my-leaf/routes/components/route-waypoints";
import * as legs from "@/my-leaf/routes/components/route-legs"
import store from "@/store";
import L from "leaflet";
import {myAxios} from "@/services/myAxios";
import {eventBus} from "@/main";

export class RouteLeafRouting extends RouteLeafDraw {

    bypassID1
    bypassID2

    async initbaserouting(el) {
        super.initbasewp(el);
    }

    getAutoRoute() {
        let pts = []

        let bounds = L.latLngBounds(this.waypoints[0].getLatLng(), this.waypoints[1].getLatLng());
        this.map.fitBounds(bounds)

        this.waypoints.forEach(waypoint => {
            pts.push({
                lat: waypoint.getLatLng().lat,
                lon: waypoint.getLatLng().lng,
                radius: this.routesStore.routeSettings.radius,
                xteLeft: this.routesStore.routeSettings.xtel,
                xteRight: this.routesStore.routeSettings.xter
            })
        })

        this.startRouteCalculation(pts, [], true)
    }

    checkSafety() {
        let pts = []

        this.waypoints.forEach(waypoint => {
            pts.push({
                lat: waypoint.getLatLng().lat,
                lon: waypoint.getLatLng().lng,
                radius: this.routesStore.routeSettings.radius,
                xteLeft: this.routesStore.routeSettings.xtel,
                xteRight: this.routesStore.routeSettings.xter
            })
        })

        this.startRouteCalculation(pts, [], false)
    }

    async getRoute(routeId) {
        return new Promise(resolve => {
            myAxios.get('api/v1/route/' + routeId)
                .then(resp => {

                    store.commit("routes/setProgress", resp.data.progress)

                    if (resp.data.status === 'calculating' && this.isAutoroutingCancel) {
                        store.commit("routes/setCalculatingStatus", false)
                        //TODO
                        this.restoreContextAfterAuto()
                        this.isAutoroutingCancel = false
                        myAxios.delete("api/v1/route/" + routeId).then(resp => {
                        })
                        resolve(resp.data)
                    }

                    if (resp.data.status === 'calculating' && resp.data.restoreRoutePoints.length > 0) {
                        this.routesStore.addRouteGeneral(resp.data.restoreRoutePoints)
                        this.drawGeneralRoute()
                        // legs.drawGeneralRoute.call(this)
                    }

                    if (resp.data.status === 'calculating' && resp.data.routeLegs.length > 0) {
                        this.routesStore.addRouteLegs(resp.data.routeLegs)
                        // legs.drawLegsByProgress.call(this)
                    }

                    if (resp.data.status === 'done') {
                        store.commit("routes/setCalculatingStatus", false)
                        if (resp.data.isSuccess) {
                            if (this.routesStore.routeResponseBackup.isBypass) {
                                let id1 = this.routesStore.routeResponseBackup.startIndex
                                let id2 = this.routesStore.routeResponseBackup.finishIndex
                                let prevResp = this.routesStore.routeResponseBackup
                                // prevResp['routePoints'].splice(id1, id2-id1, ...resp.data['routePoints'])
                                prevResp['calculatedRouteLegs'].splice(id1, id2-id1, ...resp.data['calculatedRouteLegs'])
                                prevResp['routePointInfos'].splice(id1, id2-id1, ...resp.data['routePointInfos'])
                                prevResp['routeLegs'].splice(id1, id2-id1, ...resp.data['routeLegs'])
                                resp.data = prevResp
                            }

                            this.clearRoute(false, true)
                            this.routesStore.addRouteResponse(resp.data, false, false)
                            resp.data.calculatedRouteLegs.forEach(waypoint => {
                                // waypoints.addWP.call(this, L.latLng(waypoint.lat, waypoint.lon))
                                waypoints.addWP.call(this, L.latLng(waypoint.lat, waypoint.lon), -2)
                            })
                            legs.drawLegs.call(this, true, false)

                            // this.routesStore.addRouteResponse(resp.data, false, false)


                            // console.log("aaa")
                            // this.drawRoute(resp.data)

                        }
                        else
                            console.log("aaa")
                            // this.showError(resp.data)

                        // this.restoreContextAfterAuto()
                        resolve(resp.data)
                    }

                    if (resp.data.status === 'calculating' && !this.isAutoroutingCancel) {
                        setTimeout(() => {
                            resolve(this.getRoute(routeId))
                        }, 1000);
                    }
                })
        })
    }

    startRouteCalculation(pts, zones, isAutoRoute = true, isGlobalRoute = false, isBypass = false, type = 'none') {
        this.routeRequestPoints = pts
        store.commit("routes/setRoutePointsBackup")

        // this.addNoGoLayer();
        const draught = this.routesStore.routeSettings.draught;
        const height = this.routesStore.routeSettings.height
        const radius = this.routesStore.routeSettings.radius
        const xte_l = this.routesStore.routeSettings.xtel
        const xte_r = this.routesStore.routeSettings.xter
        const speed = this.routesStore.routeSettings.speed
        const recommended = this.routesStore.routeSettings.userecommendedtracks
        // const generalroutesonly = isBypass ? false : this.routesStore.routeSettings.usegeneralroutes

        let generalroutesonly = this.routesStore.routeSettings.usegeneralroutes
        if (type === 'general') {
            generalroutesonly = true
        } else if (type === 'detailed')
            generalroutesonly = false




        const ratio = this.routesStore.routeSettings.trackratio
        const shallowratio = this.routesStore.routeSettings.shallowratio
        const shallowstrength = this.routesStore.routeSettings.avoidshallowstrength
        const avoiddistance = this.routesStore.routeSettings.avoiddistance
        const usesuez = this.routesStore.routeSettings.usesuez
        const usepanama = this.routesStore.routeSettings.usepanama
        const usebering = this.routesStore.routeSettings.usebering
        const userivers = this.routesStore.routeSettings.userivers

        store.commit("routes/resetStorage")

        // this.removeRoute()

        let nogoObjects = []
        store.state.fickle.routes.nogoObjects.forEach(item => {
            nogoObjects.push(item)
        })
        let nogoCategories = []
        store.state.fickle.routes.nogoCategories.forEach(item => {
            nogoCategories.push(item)
        })

        let gRoute = []
        let start = {}
        let finish = {}
        let points = pts
        let post_text = {}

        let URL = '/api/v1/route'
        if (isGlobalRoute) {

            store.commit("routes/setCalculatingStatus", true)
            URL = '/api/v1/mss_route'
            let portStart = store.state.fickle.routes.globalRoute.ports[0]
            let portFinish = store.state.fickle.routes.globalRoute.ports[1]

            let portStartCoordinates = store.state.fickle.routes.globalRoute.portsCoordinates[0]
            let portFinishCoordinates = store.state.fickle.routes.globalRoute.portsCoordinates[1]

            let southWest = L.latLng(portStartCoordinates.lat, portStartCoordinates.lon),
                northEast = L.latLng(portFinishCoordinates.lat, portFinishCoordinates.lon),
                bounds = L.latLngBounds(southWest, northEast);

            this.map.fitBounds(bounds)

            post_text = {
                useAutoRoute: isAutoRoute,
                useRecomendedTracks: recommended,
                resctrictedZones: zones,
                speedKnots: speed,
                points: [],
                from: portStart,
                to: portFinish,
                depth: draught,
                height: height,
                defaultXteLeft: xte_l,
                defaultXteRight: xte_r,
                defaultRadius: radius,
                waitSeconds: 0,
                recomendedTrackCost: ratio,
                shallowWaterCoeff: shallowratio,
                shallowWaterCost: shallowstrength,
                avoidDistance: avoiddistance,
                allowSuezCanal: usesuez,
                allowPanamaCanal: usepanama,
                allowBeringStrait: usebering,
                allowRivers: userivers,
                generalRouteOnly: generalroutesonly,
                resctrictedIds: nogoObjects,
                resctrictedCategories: nogoCategories,
                restoreRoutePoints: gRoute
            }
        } else {
            post_text = {
                useAutoRoute: isAutoRoute,
                useRecomendedTracks: recommended,
                resctrictedZones: zones,
                speedKnots: speed,
                points: pts,
                depth: draught,
                height: height,
                defaultXteLeft: xte_l,
                defaultXteRight: xte_r,
                defaultRadius: radius,
                waitSeconds: 0,
                recomendedTrackCost: ratio,
                shallowWaterCoeff: shallowratio,
                shallowWaterCost: shallowstrength,
                avoidDistance: avoiddistance,
                allowSuezCanal: usesuez,
                allowPanamaCanal: usepanama,
                allowBeringStrait: usebering,
                allowRivers: userivers,
                generalRouteOnly: generalroutesonly,
                resctrictedIds: nogoObjects,
                resctrictedCategories: nogoCategories,
                restoreRoutePoints: []
            }
        }

        navigator.clipboard.writeText(JSON.stringify(post_text))

        myAxios.get("api/v1/route/state").then(resp => {
            myAxios.post(URL, post_text)
                .then(resp => {
                    if (resp.data.errorCode === 0 && resp.data.id) {
                        store.commit("routes/setCalculatingStatus", true)
                        this.getRoute(resp.data.id)
                    } else {
                        alert('Error: (' + resp.data.errorCode + ') Can\'t compute routes at the moment');
                    }
                }).catch((error) => {
                if (isGlobalRoute)
                    alert('Error: (' + error + ') \nCan\'t compute routes by ports at the moment');
                else
                    alert('Error: (' + error.status + ') \nCan\'t compute routes at the moment');

                this.removeRouteWithDebug()
                this.restoreContextAfterAuto()
            })
        }).catch(error => {
            if (error.response.status === 503) {
                alert("No free slots for route calculating, try later")
                this.removeRouteWithDebug()
                this.restoreContextAfterAuto()
            } else {
                alert("ERROR CHECK: 1024")
            }

        })
    }

    startBypassCalculation(type) {
        let pts = []

        this.routesStore.backupResponse(this.bypassID1, this.bypassID2)
        // store.commit("routes/useBypass", [this.bypassID1, this.bypassID2])

        pts.push({
            lat: this.waypoints[this.bypassID1].getLatLng().lat,
            lon: this.waypoints[this.bypassID1].getLatLng().lng,
            radius: this.routesStore.routeSettings.radius,
            xteLeft: this.routesStore.routeSettings.xtel,
            xteRight: this.routesStore.routeSettings.xter
        })

        pts.push({
            lat: this.waypoints[this.bypassID2].getLatLng().lat,
            lon: this.waypoints[this.bypassID2].getLatLng().lng,
            radius: this.routesStore.routeSettings.radius,
            xteLeft: this.routesStore.routeSettings.xtel,
            xteRight: this.routesStore.routeSettings.xter
        })

        this.startRouteCalculation(pts, [], true, false, true, type)
    }

    calculateByPass(wpID1, wpID2) {
        let bounds = L.latLngBounds(this.waypoints[wpID1].getLatLng(), this.waypoints[wpID2].getLatLng());
        this.map.fitBounds(bounds)

        this.bypassID1 = wpID1
        this.bypassID2 = wpID2

        eventBus.$emit('showCalculateByPassDialog')
    }
}
