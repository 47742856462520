import {NavLeaf} from "../base/nav-leaf";
import '../styles/route-styles.css'

import * as contextControls from './components/route-context'
import * as buttonControls from './components/route-buttons';

import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure'
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css'

import {useRoutesStore} from "@/stores/routes";
import {createCheckSafetyControl} from "./components/route-buttons";
import {eventBus} from "@/main";

export class RouteLeafControls extends NavLeaf {
    wpControl = null
    moveControl = null
    nogoControl = null
    drawControl = null
    clearControl = null
    сheckSafetyControl = null
    recalculateControl = null
    recalculateWPControl = null
    safetyControl = null
    safetyWPControl = null
    measureControl = null
    weatherText = null

    isShowMapManu = true
    isCheckSafetyControlFired = false

    async initbasecontrols(el) {
        super.init(el);

        console.log("rrrr init")

        contextControls.createContextButtons.call(this)

        let button = buttonControls.createClearControl.call(this)
        this.clearControl = new button

        button = buttonControls.createCheckSafetyControl.call(this)
        this.сheckSafetyControl = new button

        //
        // let button = createClearControl()
        // this.map.addControl(this.clearControl = new button)
        //
        // button = createSafetyControl()
        // this.map.addControl(this.safetyControl = new button)
        //
        // button = createSafetyWPControl()
        // this.map.addControl(this.safetyWPControl = new button)
        //
        // button = createRecalculateControl()
        // this.map.addControl(this.recalculateControl = new button)
        //
        // button = createRecalculateWPControl()
        // this.map.addControl(this.recalculateWPControl = new button)
        //
        button = buttonControls.createWPControl.call(this)
        this.wpControl = new button

        button = buttonControls.createMoveControl.call(this)
        // this.map.addControl(this.moveControl = new button)
        this.moveControl = new button

        button = buttonControls.createMakeNoGoControl()
        this.map.addControl(this.nogoControl = new button)

        // buttonControls.createEasyButtons(this.map)

        // this.measureControl = buttonControls.createMeasureControl().addTo(this.map)
        // this.measureControl._clearMeasureControl.hidden = true
        // this.measureControl._unitControl.hidden = true

        this.weatherText = buttonControls.createWeatherTextBox().addTo(this.map)

        // this.refreshControls()
    }

    refreshControls() {
        if (this.waypoints.length > 0 && !this.clearControl._map) {
            this.map.addControl(this.clearControl)
        }

        if (this.waypoints.length > 1 && !this.сheckSafetyControl._map) {
            this.map.removeControl(this.moveControl)
            this.map.removeControl(this.wpControl)
            this.map.addControl(this.сheckSafetyControl)
            if (this.selectedWaypointIndex !== undefined) {
                this.map.addControl(this.wpControl)
                this.map.addControl(this.moveControl)
            }
        }

        if (this.waypoints.length > 0 && !this.wpControl._map && this.selectedWaypointIndex !== undefined)
            this.map.addControl(this.wpControl)

        if (this.waypoints.length > 0 && !this.moveControl._map && this.selectedWaypointIndex !== undefined)
            this.map.addControl(this.moveControl)

        if (this.waypoints.length === 0) {
            this.map.removeControl(this.clearControl)
            this.map.removeControl(this.сheckSafetyControl)
            this.map.removeControl(this.moveControl)
            this.map.removeControl(this.wpControl)
        }
    }

    showControls() {
        this.refreshControls()
        this.isShowMapManu = true
        eventBus.$emit('wp-control-select-wp', this.selectedWaypointIndex)
        if (this.isCheckSafetyControlFired)
            eventBus.$emit('wp-control-toggle-check-safety', true)
    }

    hideControls() {
        this.map.removeControl(this.clearControl)
        this.map.removeControl(this.сheckSafetyControl)
        this.map.removeControl(this.moveControl)
        this.map.removeControl(this.wpControl)
        this.map.removeControl(this.nogoControl)
        this.isShowMapManu = false
    }

    changeWeatherText = (date) => {
        if (date)
            this.weatherText._container.innerHTML = "<h6>" + 'Weather data: ' + date + "</h6>"
        else
            this.weatherText._container.innerHTML = ""
    }
}
