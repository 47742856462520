import L from "leaflet";
import store from "@/store";
import "../../extensions/leaflet-corridor"
import routesMap from "@/views/routes/RoutesMap.vue";

export function clearLegs() {
    this.legsGroup.clearLayers()
}

function drawCorridor() {
    let width = Math.max(this.routesStore.routeSettings.xtel, this.routesStore.routeSettings.xter) * 2

    const options = {
        corridor: width,
        opacity: 0.3,
        interactive: false,
        color: 'white',
    };

    let wpList = []

    this.waypoints.forEach(waypoint => {
        wpList.push(waypoint.getLatLng())
    })
    L.corridor(wpList, options).addTo(this.legsGroup);
}

export function drawLegs(updateRouteTable = true, makeRouteTable = true) {
    if (this.waypoints.length > 1) {
        let lines1 = []
        let lines2 = []
        let wpList = []
        let dangersList = []
        let isDangerLeg = false

        this.waypoints.forEach((waypoint, index) => {
            wpList.push(waypoint.getLatLng())
        })

        drawCorridor.call(this)

        let routeLine = L.polyline(wpList, {
            color: 'blue',
            weight: 2,
            clickable: false,
            interactive: false
        }).addTo(this.legsGroup);


        this.waypoints.forEach((waypoint, index) => {
            let safety = this.routesStore.getLegSafety(index)
                if (!safety) {
                dangersList.push(waypoint.getLatLng())
            } else {
                if (dangersList.length > 0) {
                    dangersList.push(waypoint.getLatLng())
                    let routeLine = L.polyline(dangersList, {
                        color: 'red',
                        weight: 2,
                        clickable: false,
                        interactive: false
                    }).addTo(this.legsGroup);
                    dangersList = []
                }
            }
        })


        this.waypoints.forEach(waypoint => {
            waypoint.bringToFront()
        })
    }


        // this.waypoints.forEach((waypoint, index) => {
        //
        //     let safety = this.routesStore.routeResponse.routeLegs[index]?.safety ?? 1
        //     if (safety === 2) {
        //         isDangerLeg = true
        //         lines2.push(wpList)
        //         wpList = []
        //         wpList.push(waypoint.getLatLng())
        //         // wpList.push(waypoint.getLatLng())
        //     } else if (safety === 1) {
        //         if (isDangerLeg) {
        //             isDangerLeg = false
        //             lines2.push(wpList)
        //             wpList = []
        //         }
        //         //wpList.push(waypoint.getLatLng())
        //     }
        //
        //
        //     wpList.push(waypoint.getLatLng())
        // })

        // lines1.push(wpList)
        //


    //
    //     lines1.forEach(line => {
    //         wpList = lines1.pop()
    //
    //         let routeLine = L.polyline(wpList, {
    //             color: 'blue',
    //             weight: 2,
    //             clickable: false,
    //             interactive: false
    //         }).addTo(this.legsGroup);
    //     })
    //
    //     lines2.forEach(line => {
    //         wpList = lines2.pop()
    //
    //         let routeLine = L.polyline(wpList, {
    //             color: 'red',
    //             weight: 2,
    //             clickable: false,
    //             interactive: false
    //         }).addTo(this.legsGroup);
    //     })
    //
    //     return
    //
    //     this.waypoints.forEach((waypoint, index) => {
    //         console.log("aaaa", this.routesStore.routeResponse.routeLegs[index].safety)
    //         let safety = this.routesStore.routeResponse.routeLegs[index].safety
    //         if (safety === 2) {
    //             alert(1)
    //             isDangerLeg = true
    //             wpList.push(waypoint.getLatLng())
    //         } else if (safety === 1) {
    //             if (isDangerLeg) {
    //                 isDangerLeg = false
    //                 lines.push(wpList)
    //                 wpList = []
    //             }
    //             wpList.push(waypoint.getLatLng())
    //         }
    //         lines.push(wpList)
    //         // wpList.push(waypoint.getLatLng())
    //     })
    //
    //     lines.forEach(line => {
    //         wpList = lines.pop()
    //         alert(wpList.length)
    //
    //         let routeLine = L.polyline(wpList, {
    //             color: 'blue',
    //             weight: 2,
    //             clickable: false,
    //             interactive: false
    //         }).addTo(this.legsGroup);
    //
    //         this.waypoints.forEach(waypoint => {
    //             waypoint.bringToFront()
    //         })
    //
    //     })
    //
    //     // drawCorridor.call(this)
    //     // let routeLine = L.polyline(wpList, {
    //     //     color: 'blue',
    //     //     weight: 2,
    //     //     clickable: false,
    //     //     interactive: false
    //     // }).addTo(this.legsGroup);
    //     //
    //     // this.waypoints.forEach(waypoint => {
    //     //     waypoint.bringToFront()
    //     // })
    // }
    if(makeRouteTable)
        this.makeRouteTable(updateRouteTable)
}
//
// export function drawGeneralRoute() {
//     // if (this.wpGlobalGroup === null)
//     //     alert(1)
//
//     this.map.removeLayer(this.wpGlobalGroup)
//     this.wpGlobalMarkers.forEach(wp => {
//         wp.remove()
//     })
//     this.wpGlobalMarkers = []
//     // this.map.addLayer(this.wpGlobalGroup);
//
//     let globalRoute = []
//     this.routesStore.routeGeneral.forEach(wp => {
//         this.addGlobalWP({lat: wp.lat, lng: wp.lon})
//         globalRoute.push([wp.lat, wp.lon])
//     })
//
//     let routeLine = L.polyline(globalRoute, {
//         color: 'blue',
//         weight: 2,
//         dashArray: '5, 5',
//         dashOffset: '0'
//     }).addTo(this.debugLegsGroup);
//
//     let bounds = routeLine.getBounds()
//     this.map.fitBounds(bounds);
// }
//
// export function drawLegsByProgress() {
//     // this.map.removeLayer(this.wpLegsGroup)
//     this.wpLegsMarkers.forEach(wp => {
//         wp.remove()
//     })
//     this.wpLegsMarkers = []
//     // this.map.addLayer(this.wpLegsGroup);
//
//     // let legs = []
//     let legs = this.routesStore.routeLegs
//
//     this.map.removeLayer(this.wpLegsGroup)
//     this.map.addLayer(this.wpLegsGroup)
//     legs.forEach(wp => {
//         this.addLegWP({lat: wp.lat, lng: wp.lon}, wp.safety)
//         // legs.push([wp.lat, wp.lon])
//     })
//
//     let leg = []
//     console.log("aaaa1", legs)
//     for (let i = 0; i < legs.length - 1; i++) {
//         leg.push([legs[i].lat, legs[i].lon])
//         leg.push([legs[i + 1].lat, legs[i + 1].lon])
//
//         let color = 'black'
//         console.log("aaaa2", legs[i].safety)
//         switch (legs[i].safety) {
//             case 1: color = 'green'
//                 break
//             case 2: color = 'red'
//                 break
//         }
//         // let color = legs[i] === 2 ? 'red' : 'green'
//
//         // if (color !== 'green') {
//
//         let routeLine = L.polyline(leg, {
//             color: color,
//             weight: 2,
//         }).addTo(this.wpLegsGroup);
//         // }
//
//         // alert(1)
//
//     }
//
// }