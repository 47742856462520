import {defineStore} from 'pinia'
import {eventBus} from "@/main";
import Vue from "vue";

export const useRoutesStore = defineStore({
    id: 'routes',
    state: () => ({
        // route: [],
        weatherSettings: {
            dateOffset: 0,
            timeOffset: 0,
        },
        routeResponse: {
            routeLength: 0,
            routePoints: [],
            routePointInfos: [],
            dangerObjects: [],
            debugInfo: [],
            restoreRoutePoints: [],
            routeLegs: [],
            calculatedRouteLegs: []
        },
        routeResponseBackup: {
            startIndex: 0,
            finishIndex: 0,
            isBypass: false,
            routeLength: 0,
            routePoints: [],
            routePointInfos: [],
            dangerObjects: [],
            debugInfo: [],
            restoreRoutePoints: [],
            routeLegs: [],
            calculatedRouteLegs: []
        },
        routeSettings: {
            draught: 5,
            height: 20,
            radius: 120,
            xtel: 120,
            xter: 120,
            speed: 10,
            trackratio: 0.7,
            shallowratio: 1,
            avoidshallowstrength: 1,
            avoiddistance: 120,
            startdate: '',
            starttime: '',
            userecommendedtracks: true,
            usegeneralroutes: true,
            usesuez: true,
            usepanama: true,
            usebering: true,
            userivers: false
        },
        routeWeather: {
            weatherInWaypoints: []
        }
    }),
    getters: {
        getStartDate: (state) => state.routeSettings.startdate,
        getStartTime: (state) => state.routeSettings.starttime,
        getSpeed: (state) => state.routeSettings.speed,
        getWaypoints: (state) => state.routeResponse.routePoints,
        getWaypointsCount: (state) => state.routeResponse.routePoints.length,
        getLeg: (state) => {
            return (index) => state.routeResponse.routePoints[index]
        },
        getLegInfo: (state) => {
            return (index) => state.routeResponse.routePoints[index].info
        },
        getLegSafety: (state) => {
            return (index) => state.routeResponse.routePoints[index]?.isDepthSafe ?? true
        },
        getLegWarnings: (state) => {
            return (index) => state.routeResponse.routePoints[index]?.overallSafety ?? 'safe'
        },
        getLegHazards: (state) => {
            return (index) => state.routeResponse.routePoints[index]?.dangerObjects ?? []
        }
    },
    actions: {
        initWeather(count) {
            let points = []
            this.routeWeather.weatherInWaypoints.forEach(item => {
                points.push({temperature: 'N/A', wind: 'N/A', windAngle: 'N/A', datetime: 0})
            })
            this.routeWeather.weatherInWaypoints = points;
        },
        addWeather(val, index) {
            console.log("aaaa", val, index)
            // this.routeWeather.weatherInWaypoints[index] = val;
            Vue.set(this.routeWeather.weatherInWaypoints, index, val)
        },
        addRouteResponse(val, isNeedCheckSafety, updateRouteTable) {
            function findUpdatedElement(arr1, arr2) {
                return arr1.findIndex((element, index) =>
                    element?.lat !== arr2[index]?.lat || element.lon !== arr2[index].lon);
            }

            if (updateRouteTable) {
                if (this.routeResponse.routePoints.length !== val.calculatedRouteLegs.length) {
                    eventBus.$emit('wp-control-toggle-check-safety', true)
                } else {
                    this.routeResponse.routePoints.forEach((item, index) => {
                        if (item.lat !== val.calculatedRouteLegs[index].lat || item.lon !== val.calculatedRouteLegs[index].lon) {
                            eventBus.$emit('wp-control-toggle-check-safety', true)
                        }
                    })
                }
            }

            if (this.routeResponse.routePoints.routeLength === 0) {
                this.routeResponse.routeLength = val.routeLength ?? 0
                this.routeResponse.routePoints = val.calculatedRouteLegs
                this.routeResponse.calculatedRouteLegs = val.calculatedRouteLegs
                this.routeResponse.routePointInfos = val.routePointInfos
                this.routeResponse.dangerObjects = val.dangerObjects ?? []
                this.routeResponse.debugInfo = val.debugInfo ?? []
                this.routeResponse.restoreRoutePoints = val.restoreRoutePoints ?? []
                this.routeResponse.routeLegs = val.routeLegs ?? []
            }

            if (this.routeResponse.routePoints.routeLength > 0 && updateRouteTable) {
                if (this.routeResponse.routePoints.length === val.calculatedRouteLegs.length) {
                    let index = findUpdatedElement(this.routeResponse.routePoints, val.routePoints)
                    if (index !== -1) {
                        this.routeResponse.routePoints[index].lat = val.calculatedRouteLegs[index].lat
                        this.routeResponse.routePoints[index].lon = val.calculatedRouteLegs[index].lon
                        this.routeResponse.routeLegs[index].lat = val.calculatedRouteLegs[index].lat
                        this.routeResponse.routeLegs[index].lon = val.calculatedRouteLegs[index].lon
                    }
                }

                if (this.routeResponse.routePoints.length > val.calculatedRouteLegs.length) {
                    let index = findUpdatedElement(this.routeResponse.routePoints, val.calculatedRouteLegs)
                    if (index !== -1) {
                        this.routeResponse.routePoints.splice(index, 1)
                        this.routePointInfos.routeLegs.splice(index, 1)
                        this.routeResponse.routeLegs.splice(index, 1)
                    }
                }

                if (this.routeResponse.routePoints.length < val.calculatedRouteLegs.length) {
                    let index = findUpdatedElement(val.calculatedRouteLegs, this.routeResponse.routePoints)
                }

            } else {
                this.routeResponse.routeLength = val.routeLength ?? 0
                this.routeResponse.routePoints = val.calculatedRouteLegs
                this.routeResponse.calculatedRouteLegs = val.calculatedRouteLegs
                this.routeResponse.routePointInfos = val.routePointInfos
                this.routeResponse.dangerObjects = val.dangerObjects ?? []
                this.routeResponse.debugInfo = val.debugInfo ?? []
                this.routeResponse.restoreRoutePoints = val.restoreRoutePoints ?? []
                this.routeResponse.routeLegs = val.routeLegs ?? []
            }

            let i = 0
            this.routeResponse.routePoints.forEach(item => {
                item.id = i++
            })

        },
        backupResponse(startIndex, finishIndex) {
            this.routeResponseBackup.startIndex = startIndex
            this.routeResponseBackup.finishIndex = finishIndex
            this.routeResponseBackup.isBypass = true
            this.routeResponseBackup.routeLength = this.routeResponse.routeLength
            this.routeResponseBackup.routePoints = this.routeResponse.routePoints
            this.routeResponseBackup.calculatedRouteLegs = this.routeResponse.calculatedRouteLegs
            this.routeResponseBackup.routePointInfos = this.routeResponse.routePointInfos
            this.routeResponseBackup.dangerObjects = this.routeResponse.dangerObjects
            this.routeResponseBackup.debugInfo = this.routeResponse.debugInfo
            this.routeResponseBackup.restoreRoutePoints = this.routeResponse.restoreRoutePoints
            this.routeResponseBackup.routeLegs = this.routeResponse.routeLegs
        },
        addRouteDangerObjects(val) {
            this.routeDangerObjects = val
        },
        addRouteGeneral(val) {
            this.routeGeneral = val
        },
        addRouteLegs(val) {
            this.routeLegs = val
        },
        addRouteSettings(val) {
            this.routeSettings = val
        },
        addRouteDate(val) {
            this.routeSettings.startdate = val
        },
        addRouteTime(val) {
            this.routeSettings.starttime = val
        },
        addNoGoZone(val) {
            this.nogoZones.push(val)
        },
        deleteNoGoZone(val) {
            let index = this.nogoZones.findIndex(p => p.name == val);
            this.nogoZones.splice(index, 1);
        },
        addWeatherSettings(dateOffset, timeOffset) {
            this.weatherSettings.dateOffset = dateOffset
            this.weatherSettings.timeOffset = timeOffset
        },
        reset() {
            this.route = []
            this.routeResponse = {
                routeLength: 0,
                routePoints: [],
                routePointInfos: [],
                dangerObjects: [],
                debugInfo: [],
                restoreRoutePoints: [],
                routeLegs: [],
                calculatedRouteLegs: []
            }
        },

        resetBypassBackup() {
            this.routeResponseBackup = {
                startIndex: 0,
                finishIndex: 0,
                isBypass: false,
                routeLength: 0,
                routePoints: [],
                routePointInfos: [],
                dangerObjects: [],
                debugInfo: [],
                restoreRoutePoints: [],
                routeLegs: [],
                calculatedRouteLegs: []
            }


            // this.routeResponseBackup = {
            //     routeLength: 0,
            //     routePoints: [],
            //     routePointInfos: [],
            //     dangerObjects: [],
            //     debugInfo: [],
            //     restoreRoutePoints: [],
            //     routeLegs: []
            // }
        }
    },
    persist: false
})
